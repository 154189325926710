<script>
export default {
	hookZone: ['below-totals'],
}
</script>

<template>
	<div class="px-2 pt-2 pb-12 pb-md-2 font-0" style="color: #35a3a8">
		<v-divider />
		<div class="px-4">
			<div class="font-weight-bold py-2">Importante</div>
			<div class="pb-2">
				Debido a la situación actual, las entregas podrán sufrir algunas demoras. El presente pedido queda
				sujeto a disponibilidad de stock. En caso que se verifique falta de stock o que nuestros
				proveedores alteren las fechas de disponibilidad -que figuran en tu orden de compra-, recibirás la
				correspondiente nota de crédito y el reembolso de lo abonado en el medio de pago utilizado. Muchas
				gracias por tu comprensión.
			</div>
			<div class="pb-2">
				Todas las compras online, realizadas con tarjetas crédito y débito, son evaluadas con el objetivo
				de prevenir y controlar transacciones fraudulentas. Estas tareas pueden generar demoras o
				cancelaciones.
			</div>
			<div class="pb-2">
				Por seguridad, se realizará la entrega de la compra únicamente al titular de la Tarjeta con la que
				fue abonada. Sin Excepciones.
			</div>
		</div>
	</div>
</template>

