import { loadScript, unloadScript } from '@/utils/load-script'

export default (options) => {
	if (options.ssr) return

	const cid = options.srv('clearSaleClientId')
	if (!cid) return

	let prepare = (w, a, b) => {
		w[a] = b
		w[b] = function () {
			w[b].q.push(arguments)
		}
		w[b].q = []
		w[b].l = 1 * new Date()
		return w[b]
	}

	// FP (session id)
	let fpsid = null
	let loadfp = (sid) => {
		if (fpsid === sid) return
		let src = window.location.protocol + '//device.clearsale.com.br/p/fp.js'
		if (fpsid && fpsid !== sid) unloadScript(src)
		fpsid = null
		let csdp = prepare(window, 'CsdpObject', 'csdp')
		csdp('app', cid)
		csdp('sessionid', sid)
		loadScript(src).then(() => {
			fpsid = sid
		})
	}

	options.eventer.listen('checkout:step-loaded', async ({ order }) => {
		loadfp(order.chash)
	})

	// CS
	let loadcs = () => {
		let src = window.location.protocol + '//device.clearsale.com.br/m/cs.js'
		let csdm = prepare(window, 'CsdmObject', 'csdm')
		csdm('app', cid)
		csdm('mode', 'manual')

		options.eventer.listen('route:change', ({ to, from }) => {
			if (to.path == '/') csdm('send', 'home')
			else if (to.name == 'user.account') csdm('send', 'edit-account')
			else if (to.name.startsWith('checkout') && !from?.name?.startsWith('checkout')) {
				csdm('send', 'checkout')
			} else if (to.name == 'shop') {
				if (to.query.search) {
					let page = to.query.page || 1
					let keywords = to.query.search.replace(/,/g, ' ')
					csdm('send', 'search', `page-number=${page}, key-words=${keywords}`)
				} else {
					csdm('send', 'category')
				}
			}
		})
		options.eventer.listen('product:view', ({ product, variant }) => {
			let safeName = product.name
				.replace(/[^a-zA-Z0-9]/g, ' ')
				.replace(/\s+/g, ' ')
				.trim()
			csdm('send', 'product', `name=${safeName}, sku=${variant.sku}`)
		})
		options.eventer.listen('checkout:step-submit', async ({ stepKey, order }) => {
			if (stepKey === 'confirm') {
				let paymentType = null
				let payment = order.payments?.[0]
				switch (payment?.methodKey) {
					case 'CreditCard':
						paymentType = payment.data?.brand?.type == 'debit' ? 'debit card' : 'credit card'
						break
					case 'Paypal':
						paymentType = 'Paypal'
						break
					case 'WireTransfer':
						paymentType = 'bank transfer'
						break
					default:
						paymentType = 'other'
						break
				}
				csdm('send', 'purchaseconfirmation', `code=${order.code}, payment-type=${paymentType}`)
				await new Promise((resolve) => setTimeout(resolve, 3000))
			}
		})
		options.eventer.listen('signup-form:view', () => {
			csdm('send', 'create-account')
		})
		options.eventer.listen('forgot-password-form:view', () => {
			csdm('send', 'password-reset')
		})
		options.eventer.listen('cart-drawer:view', () => {
			csdm('send', 'cart')
		})
		options.eventer.listen('user:signin:failed', () => {
			csdm('send', 'login-fail')
		})
		loadScript(src)
	}

	loadcs()
}

