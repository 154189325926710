<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
export default {
	hookZone: ['below-delivery-done'],
	mixins: [checkoutMixin],
	computed: {
		showAlert() {
			return this.checkout.order.items.some((item) => item.makingTime)
		},
	},
}
</script>

<template>
	<div v-if="showAlert" class="warning darken-2 pa-2 d-flex align-start rounded-lg">
		<v-icon color="white" small left>mdi-alert-circle</v-icon>
		<div>
			La mercaderia será entregada, enviada o acopiada <b>cuando tu pago se haya acreditado</b> y haya
			<b>disponibilidad de todos los productos</b> en tu compra.
		</div>
	</div>
</template>

