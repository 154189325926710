<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
export default {
	hookZone: ['below-confirm-active'],
	mixins: [checkoutMixin],
	inject: ['getAppMessageDialog'],
	data() {
		return {
			accepted: false,
		}
	},
	computed: {
		showAlert() {
			return this.checkout.order.items.some((item) => item.makingTime)
		},
	},
	mounted() {
		if (!this.showAlert) return
		this.$eventer().listen('checkout:before-confirm', () => {
			if (this.accepted) return true

			return new Promise((resolve) => {
				this.$refs.dialog.open({
					type: 'custom',
					maxWidth: '500px',
					onAccept: () => {
						this.accepted = true
						resolve(true)
					},
					onCancel: () => resolve(false),
				})
			})
		})
	},
}
</script>

<template>
	<AppMessageDialog v-if="showAlert" ref="dialog">
		<div class="text-center pt-6">
			<v-icon color="success" style="font-size: 85px">mdi-check-circle-outline</v-icon>
			<div class="font-weight-bold font-4 pt-3">Atención!</div>
			<div class="font-2 pt-3">
				Te informamos que la compra que estas por realizar tiene productos con demora en su entrega.
			</div>
			<div class="font-2">
				Presiona <b>ACEPTAR</b> si estás de acuerdo, o CANCELAR para revisar tu orden.
			</div>
		</div>
	</AppMessageDialog>
</template>

