<script>
import checkoutMixin from '@/v-shop/checkout/checkout-mixin'
export default {
	hookZone: ['below-price'],
	props: {
		OrderSummaryItem: Object,
	},
	computed: {
		time() {
			return this.OrderSummaryItem.item.makingTime
		},
	},
}
</script>

<template>
	<div class="font-0 warning--text font-weight-bold" v-if="time && time < 60">
		Entrega en <b>{{ OrderSummaryItem.item.makingTime }} días</b> a partir de tu compra
	</div>
	<div class="font-0 warning--text font-weight-bold" v-else-if="time && time >= 60">
		Producto bajo pedido. Consulte demora en entrega.
	</div>
</template>

